import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ProductView from "../components/productView";

const Honigsorten = () => {
    const klassischeSorten = [
        {
            name: "Cremehonig",
            info: "Cremehonig ist Waldblütenhonig der durch langes Rühren diese cremige Konsistenz erhält. Ein Stück wertvolle Handarbeit aus der Südsteiermark!",
            sizes: "250g bis 500g",
            image: "Cremehonig.png",
            alt: "Cremehonig"
        },
        {
            name: "Waldhonig",
            info: "Ein dunkler, milder sowie bekömmlicher Brotaufstrich. Der reine Waldhonig erhält seine Besonderheit dadurch, dass er ausschließlich in höheren Lagen geerntet wird.",
            sizes: "125g bis 1kg",
            image: "Waldhonig.png",
            alt: "Waldhonig"
        },
        {
            name: "Waldblütenhonig",
            info: "Ein heller, zähflüssiger Honig der in den Mischwäldern und auf den Blumenwiesen rund um die Region Radkersburg von unseren Bienenvölkern gesammelt wird.",
            sizes: "125g bis 1kg",
            image: "Waldbluetenhonig.png",
            alt: "Waldblütenhonig"
        },
        {
            name: "Kastanienhonig",
            info: "Diese ganz besondere Honigsorte bietet ein kräftiges Aroma mit leicht bitterer Note.\nKastanienhonig ist auch gesundheitsfördernd und wirkt positiv auf den Kreislauf und das Immunsystem.",
            sizes: "125g bis 1kg",
            image: "Kastanienhonig.png",
            alt: "Kastanienhonig"
        }
    ];
    const fruchtSorten = [
        {
            name: "Erdbeer Honig",
            info: "Zutaten:\nÖsterreichischer Honig,\n5% vakuumgetrocknete Erdbeeren (entspricht 60% Frischfrucht),\nZitronensaft",
            sizes: "220g",
            image: "Erdbeerhonig.png",
            alt: "Erdbeer Honig"
        },
        {
            name: "Aronia Honig",
            info: "Zutaten:\nÖsterreichischer Honig,\n5% vakuumgetrocknete Aronia (entspricht 60% Frischfrucht),\nZitronensaft",
            sizes: "220g",
            image: "Aroniahonig.png",
            alt: "Aronia Honig"
        },
        {
            name: "Himbeer Honig",
            info: "Zutaten:\nÖsterreichischer Honig,\n5% vakuumgetrocknete Himbeeren (entspricht 60% Frischfrucht),\nZitronensaft",
            sizes: "220g",
            image: "Himbeerhonig.png",
            alt: "Himbeer Honig"
        },
        {
            name: "Zimt Honig",
            info: "Zutaten:\nÖsterreichischer Honig,\nZimt",
            sizes: "220g",
            image: "Zimthonig.png",
            alt: "Zimt Honig"
        }
    ];
    const nussHonig = [
        {
            name: "Honig mit Kürbiskernen",
            info: "Cremehonig vermengt mit Gehackten Kürbiskernen\nTipp: Mit Chili verfeinert entsteht eine etwas andere Art von Brotaufstrich",
            sizes: "250g",
            image: "Kuerbiskernhonig.png",
            alt: "Honig mit Kürbiskernen"
        },
        {
            name: "Haselnusshonig",
            info: "Diese Besondere Honigkreation bietet Ihnen Abwechslung am Frühstückstisch.\nBei Kindern sehr beliebt!",
            sizes: "250g",
            image: "Haselnusshonig.png",
            alt: "Haselnusshonig"
        },
        {
            name: "Nusshonig",
            info: "Walnüsse in Blütenhonig eingelegt\nTipp: Schmeckt in Kombination mit Müsli hervorragend, und ist außerdem ein ausgezeichnetes „Nervenfutter“",
            sizes: "250g",
            image: "Nusshonig.png",
            alt: "Nusshonig"
        }
    ];

    return (
        <Layout>
            <SEO title="Honigsorten" />
            <ProductView headline="Genießen Sie unsere Klassiker." color="FFE6B7" subHeadline="Genießen sie den unveränderten Honiggeschmack mit unseren klassischen Honigsorten." products={klassischeSorten} />
            <ProductView headline="Entdecken Sie unsere Fruchtsorten." color="FFC4B7" subHeadline="Der liebliche Honig-Geschmack verfeinert mit ausgewählten Früchten. Versuchen Sie unsere Fruchtsorten." products={fruchtSorten} />
            <ProductView headline="Probieren Sie Honig mit Nuss." color="D5A982" subHeadline="Entdecken Sie den vielfältigen Geschmack von Honig mit unseren Nuss-Sorten. Ob als Brotaufstrich oder pikante Variante für’s Käsebuffet." products={nussHonig} />
        </Layout>
    )
}

export default Honigsorten