import React, {useRef} from "react";
import gsap from "gsap";
import Image from "./image"
import productStyles from "./product.module.css";

const Product = (props) => {
    let info = useRef(null);
    let closeInfoButton = useRef(null);

    function openInfo() {
        gsap.to(info, {
            duration: 0.8,
            top: 0,
            ease: "power3.inOut"
        })
        gsap.from(closeInfoButton, {
            duration: 0.4,
            y: 20,
            opacity: 0,
            delay: 0.9
        })
    }

    function closeInfo() {
        gsap.to(info, {
            duration: 0.8,
            top: '100%',
            ease: "power3.inOut"
        })
    }


    // TODO: change Image alt={} to be dynamic

    return (
        <div className={productStyles.item}>
            <div className={productStyles.imageContainer}>
                <Image alt={props.alt} filename={props.image} />
            </div>
            <span className={productStyles.itemHeadline}>{props.name}</span>
            <div onClick={openInfo} style={{ display: props.info ? "flex" : "none" }} className={productStyles.openInfoButton}>
                <span>Mehr darüber</span>
            </div>
            <div className={productStyles.info} style={{ opacity: props.info ? "1" : "0" }} ref={el => (info = el)}>
                <span className={productStyles.infoHeadline}>{props.name}</span>
                <p>{props.info}</p>
                <span className={productStyles.size}>{props.sizes}</span>
                <div onClick={closeInfo} className={productStyles.closeInfoButton} ref={el => (closeInfoButton = el)}>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}

export default Product