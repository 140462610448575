import React from "react";
import { Link } from "gatsby";

import productViewStyles from "./productView.module.css";
import Product from "./product";
import infoIcon from "../images/info.svg";

const ProductView = (props) => (
    <div className={productViewStyles.container}>
        <div className={productViewStyles.textContainer}>
            <h2 className={productViewStyles.headline} style={{background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 50%, #' + props.color + ' 50%)'}}>{props.headline}</h2>
            <p className={productViewStyles.subHeadline}>{props.subHeadline}</p>
            <div className={productViewStyles.hintContainer}>
                <div>
                    <img src={infoIcon} alt="Info" />
                    <p>Auf Anfrage versenden wir unsere Produkte auch. Kontaktieren Sie uns einfach dazu.</p>
                </div>
                    <Link to="/kontakt/">Kontakt</Link>
            </div>
        </div>
        <div className={productViewStyles.layout}>
            {
                props.products.map((product, index) =>
                    <Product key={index} name={product.name} info={product.info} sizes={product.sizes} image={product.image} alt={product.alt} />
                )
            }
        </div>
    </div>
)

export default ProductView

